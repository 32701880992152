import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Seo from "../../components/layout/seo";
import Frozen from "../../components/pages/frozen";

const data = {
  intro:
    "The Frozen treatment is a HIGH-TECHNOLOGY and HIGH-PERFORMANCE ANT ICORROSI VE coating, which is laid over the blades and is approximately 2-micron thick, including the cutting edge up to the depth of cut being provided for.",
  blocks: [
    {
      type: "paragraph",
      title: "High-performance coating",
      text: (
        <p>
          This type of coating protects the body of the blade and prevents it
          from overheating and then warping while cutting, as these tools are
          not equipped with rakers. The high non-stick level of this coating
          allows maximum chip ejection and prevents the resin from building up
          mainly close to the tips (a disadvantage of all tools that are
          employed for these kinds of work).
          <br />
          The tool keeps constantly clean and no maintenance intervention is
          required besides tip sharpening. Thanks to this coating, the tool
          undergoes reduced stresses while cutting, thus leading to a longer
          blade lifespan and an increased cutting life.
          <br />
          Therefore, the best employment conditions allow making the most of the
          potentials of this tool, as the cutting life can be even four times
          longer than a conventional tool. Also consider that you can employ the
          tip up to total consumption. The employment of these tools also
          contributes towards reducing the power absorbed by the motor of the
          machine.
        </p>
      ),
    },
    {
      type: "comparison",
      title: "Friction test",
      text: (
        <p>
          Lower temperature of about 1/3 thanks to our treatment, and only
          slight traces of blackening after prolonged use.
          <br />
          <br />
          A treated coated sawblade stands out because it lowers temperature by
          about one third, thus showing on the plate only minor traces of
          blackening - after a sustained use.
          <br />
          <br />A non coated sawblade posseses lower resistence towards heat,
          therefore provoking an almost instant overheating effect on certain
          parts of the sawblades body.
        </p>
      ),
      imgIs: (
        <StaticImage
          formats={["web", "auto"]}
          src="../../resources/images/specifiche/sfreg-is.png"
          layout="fullWidth"
          alt="anti-friction effect of international saws"
        />
      ),
      imgComp: (
        <StaticImage
          formats={["web", "auto"]}
          src="../../resources/images/specifiche/sfreg-comp.png"
          layout="fullWidth"
          alt="rubbing effect on competitors' blades"
        />
      ),
    },
    {
      type: "comparison",
      title: "Does not rust",
      text: (
        <p>
          Thanks to the Frozen treatment, International Saws blades do not rust,
          having a particular resistance to moisture than 5 times higher than
          the norm.
          <br />
          <br />
          Due to the special treatment shows a resistance to humidity and
          therefore to corrosion (rust) about 5 time greater than the standard
          product..
          <br />
          <br />A non coated sawblade greatly fears humidity. Areas such as the
          teeth, dampening slots and other grooves and cuts on the sawblades
          body are at risk.
        </p>
      ),
      imgIs: (
        <StaticImage
          formats={["web", "auto"]}
          src="../../resources/images/specifiche/rugg-is.png"
          layout="fullWidth"
          alt="no rust on the blades international saws"
        />
      ),
      imgComp: (
        <StaticImage
          formats={["web", "auto"]}
          src="../../resources/images/specifiche/rugg-comp.png"
          layout="fullWidth"
          alt="effect of rust on competitors' blades"
        />
      ),
    },
    {
      type: "comparison",
      title: "Outstanding no-stick",
      text: (
        <p>
          The special Frozen treatment gives the blades to mark International
          Saws outstanding non-stick properties.
          <br />
          <br />
          The special “Frozen treatment”, thanks to its non stick features is
          ideal for cutting resinous woods.
          <br />
          <br />A non-coated sawblade is receptive to the depositing of resins
          released by certain types of wood.
        </p>
      ),
      imgIs: (
        <StaticImage
          formats={["web", "auto"]}
          src="../../resources/images/specifiche/antiad-is.png"
          layout="fullWidth"
          alt="non-stick properties of international saws"
        />
      ),
      imgComp: (
        <StaticImage
          formats={["web", "auto"]}
          src="../../resources/images/specifiche/antiad-comp.png"
          layout="fullWidth"
          alt="competitors' blades are not non-sticky"
        />
      ),
    },
  ],
};

const TrattamentoFrozen = ({ location }) => (
  <>
    <Seo title="Frozen treatment" desc="" />
    <Frozen data={data} location={location} />
  </>
);

export default TrattamentoFrozen;
